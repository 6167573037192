import React, { useState } from 'react'
import styled from 'styled-components'
import { breakpoints, ZIndex, useResolution, City, slugify, AlgoliaProduct, useLocation } from '@ecommerce/shared'
import { Configure } from 'react-instantsearch-dom'
import { navigate } from '@reach/router'
import { Anchor } from '../common'
import Brands from './Brands'
import { Icon } from '../Icon/Icon'
import Breadcrumb from '../Breadcrumbs'
import Filter from '../Algolia/Filter'
import Loading from '../ShowLoading'
import ResultProducts from '../Algolia/ResultProducts'
import { InfoEmptySearch, sortListAlgolia } from '../../mocks'
import secrets from '../../config/secrets'
import CustomSort from '../Algolia/CustomSort'
import { Filter as ContentfulFilter } from '../../graphql/contentfulTypes'
import useContentfulGlobal from '../../hooks/useContentfulGlobal'

declare const window: Window

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .CategoryContainer__ {
    &breadcrumb {
      width: 100%;
      background: ${(props) => props.theme.colors.white};
      z-index: ${ZIndex.low};
      > div {
        height: 38px;
        padding: 22px 0 0 17px;
        background-color: ${(props) => props.theme.colors.medium};
      }
      .Breadcrumbs__ {
        &go_back {
          display: none;
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .CategoryContainer__ {
      &breadcrumb {
        width: 100%;
        > div {
          height: 35px;
          padding: 23px 0 0 0;
          background-color: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`
const Wrapper = styled.div<{ isOpenFilter: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .CategoryContainer__ {
    &left {
      width: ${(props) => (props.isOpenFilter ? '100%' : '0')};
      height: 100vh;
      top: 0;
      right: 0;
      position: fixed;
      transition: 100ms;
      z-index: ${ZIndex.ultraHigh};
      &-title,
      &-go_back {
        display: none;
      }
    }
    &right {
      width: 100%;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .CategoryContainer__ {
      &left {
        width: ${(props) => (props.isOpenFilter ? '375px' : '0')};
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .CategoryContainer__ {
      &left {
        width: 25%;
        height: auto;
        right: auto;
        position: relative;
        z-index: inherit;
        &-go_back {
          width: 100%;
          height: 52px;
          padding-top: 10px;
          display: flex;
          align-items: center;
          svg {
            width: 17px;
            height: 17px;
          }
          span {
            width: auto;
            padding-left: 10px;
            font-size: 14px;
            color: ${(props) => props.theme.colors.black};
            cursor: pointer;
          }
        }
        .CategoryFilter__ {
          &clear {
            height: 35px;
            padding: 10px 3px;
          }
          &option {
            padding: 0;
          }
        }
      }
      &right {
        width: 70%;
      }
    }
  }
`
const Title = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background-color: ${(props) => props.theme.colors.medium};
  display: flex;
  align-items: center;
  z-index: ${ZIndex.low};
  span {
    font-size: 24px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
  }
  .CategoryContainer__ {
    &right {
      &-title {
        &-order {
          /* hidden element and style for this review */
          display: none;
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    height: 42px;
    padding: 0 0 0 20px;
    background-color: ${(props) => props.theme.colors.white};
    justify-content: space-between;
    .CategoryContainer__ {
      &right {
        &-title {
          &-sort {
            align-items: flex-end;
            cursor: pointer;
            &-icon {
              object-fit: contain;
              color: ${(props) => props.theme.colors.black};
              margin-left: auto;
            }
          }
          &-name {
            width: 75px;
            height: 18px;
            font-size: 13px;
            font-weight: normal;
            color: ${(props) => props.theme.colors.black};
          }
        }
      }
    }
  }
`

const BlockedBox = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(3, 15, 28, 0.79);
  position: fixed;
  z-index: ${ZIndex.medium};
`

function blockedScreen(isBlocked: boolean) {
  const element = window.document.getElementById('layout')
  if (element) {
    if (isBlocked) {
      element.style.position = 'fixed'
    } else {
      element.style.position = 'absolute'
    }
  }
}

interface Data {
  id: string
  name: string
  brandScore?: number
  contentfulid?: string
  image: {
    file: {
      url: string
    }
  }
}
interface Node {
  node: Data
}
interface Props {
  category: string
  brands: Node[]
  currentCity: City
  categoryFilters: ContentfulFilter[]
  excludeFilters?: { param: string; value: string }[]
  refinementListSelected: string[]
  onHitClick?: (hit?: AlgoliaProduct) => void
}
const CategoryContainer = (props: Props) => {
  const { category, brands, currentCity, refinementListSelected, onHitClick } = props
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const { isDesktop } = useResolution()

  const { isBolivia } = useLocation()

  function onOpenFilter() {
    setIsOpenFilter(true)
    blockedScreen(true)
  }

  function onCloseFilter() {
    setIsOpenFilter(false)
    blockedScreen(false)
  }

  const items = [
    {
      name: 'Inicio',
      url: `/${currentCity.slug}`,
    },
    {
      name: 'Categorías',
      url: `/${currentCity.slug}/category`,
    },
    {
      name: category,
      url: '',
    },
  ]

  const {
    mainAlcoholCategorySlug,
    alcoholCategories,
    mainFoodCategorySlug,
    foodCategories,
    mainWineCategorySlug,
    wineCategories,
  } = useContentfulGlobal()

  const buildFilters = (categories: string[]) => {
    const string = categories.map((cat) => `"${cat}"`).join(` OR mainCategoryName:`)

    return `AND (mainCategoryName:${string})`
  }

  function getFilters() {
    if (category === mainAlcoholCategorySlug) return buildFilters(alcoholCategories)
    if (category === mainFoodCategorySlug) return buildFilters(foodCategories)
    if (category === mainWineCategorySlug) return buildFilters(wineCategories)

    return `AND mainCategoryName:"${category}"`
  }

  const excludeString = props.excludeFilters
    ? props.excludeFilters.reduce((str, filter) => `${str} AND NOT ${filter.param}:${filter.value}`, '')
    : ''

  return (
    <Container>
      <Loading />
      {isOpenFilter && <BlockedBox />}
      <div className="CategoryContainer__breadcrumb">
        <Breadcrumb items={items} />
      </div>
      <Wrapper isOpenFilter={isOpenFilter}>
        <div className="CategoryContainer__left">
          <Anchor className="CategoryContainer__left-go_back" onClick={() => navigate(`/${currentCity.slug}`)}>
            <Icon iconId="arrow_left" />
            <span>Volver</span>
          </Anchor>
          <Filter
            onCloseFilter={onCloseFilter}
            filters={props.categoryFilters}
            refinementListSelected={refinementListSelected}
          />
        </div>

        <div className="CategoryContainer__right">
          <Title className="CategoryContainer__right-title">
            <span>{category}</span>
            {isDesktop && <CustomSort items={sortListAlgolia} defaultRefinement={secrets.ALGOLIA_INDEX_NAME} />}
            <div className="CategoryContainer__right-title-order">
              <Anchor>
                <Icon iconId="sort" />
              </Anchor>
              <span className="CategoryContainer__right-title-name">Destacados</span>
            </div>
          </Title>
          <Brands
            brands={brands.map((brand) => ({
              label: brand?.node?.name || '',
              pimId: brand?.node?.contentfulid || undefined,
              src: `https:${brand?.node?.image?.file?.url || ''}`,
              score: brand.node?.brandScore ?? 0,
            }))}
            onOpenFilter={onOpenFilter}
          />
          <Configure
            hitsPerPage={24}
            filters={`${isBolivia() ? 'hasStock:true AND' : ''} slugLocation:${slugify(
              currentCity.commerceLayer.stockLocation.name,
            )} ${getFilters()} ${excludeString}`}
          />
          <ResultProducts urlImage={InfoEmptySearch.urlImage} text={InfoEmptySearch.text} onHitClick={onHitClick} />
        </div>
      </Wrapper>
    </Container>
  )
}

export default CategoryContainer
