import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import { connectRefinementList } from 'react-instantsearch-dom'
import { RefinementListProvided } from 'react-instantsearch-core'
import { Image, breakpoints, useResolution, ZIndex } from '@ecommerce/shared'
import { Anchor } from '../common'
import { SwiperParams } from '../../types/Constants'

const Wrapper = styled.div<{ IconRight: string; IconLeft: string }>`
  min-height: 104px;
  padding: 10px 20px;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;

  .swiper-container {
    margin: 0;
    width: 100%;
    z-index: ${ZIndex.default};
  }
  .swiper-button {
    display: none;
    &-next,
    &-prev {
      background-size: 24px 24px;
      background-position: center;
      display: none;
      ::after {
        content: none;
      }
    }
  }

  .item {
    img {
      border: 2px solid ${({ theme }) => theme.colors.white};
      padding: 5px;
    }

    &.is-refined {
      img {
        border-color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .swiper-slide {
    width: 100px;
  }

  .CategoryBrands__ {
    &button {
      width: 80px;
      height: 80px;
      margin: 0 10px;
      border-radius: 50%;
      box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
    }
    &image {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  @media (${breakpoints.desktop.min}) {
    .swiper-button {
      &-disabled {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
      }
      &-next {
        right: 5px;
        background-image: url(${(props) => (props.IconRight ? props.IconRight : '')});
      }
      &-prev {
        left: 5px;
        background-image: url(${(props) => (props.IconLeft ? props.IconLeft : '')});
      }
      &-next,
      &-prev {
        display: block;
        background-repeat: no-repeat;
        color: ${(props) => props.theme.colors.primary};
        height: 35px;
        width: 35px;
        opacity: 0.5;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.dark};
        user-select: none;
        outline: none;
        border: none;
        transition: all 0.5s;
        -moz-outline-style: none;
        :hover {
          background-color: ${(props) => props.theme.colors.primary};
          opacity: 0.8;
        }
      }
    }
  }
`

export interface BrandProps {
  label: string
  src: string
  score: number
  pimId?: string
}

interface OwnProps {
  brands: BrandProps[]
  cleanBrands: (value: boolean) => void
}

type Props = RefinementListProvided & OwnProps

const RefinementList = (props: Props) => {
  const urlParam = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '').get('brandName')

  const { items, brands, refine, cleanBrands, currentRefinement } = props
  const [color, setColor] = useState(true)
  const brandsFiltered = items
    .map((item) => {
      const brand = brands.find(
        (value) =>
          (value.pimId ? value.pimId.toLowerCase() : value.label.toLocaleLowerCase()) ===
          item.label.toLocaleLowerCase(),
      )

      return {
        src: brand?.src || '',
        label: item.label || '',
        value: item.value,
        score: brand?.score ?? 0,
        isRefined: item.isRefined,
      }
    })
    .sort((a, b) => {
      const aScore = a.score
      const bScore = b.score

      return bScore - aScore
    })

  useEffect(() => {
    if (urlParam) {
      refine([urlParam])
    }
  }, [])

  useEffect(() => {
    cleanBrands(brandsFiltered.length > 0)
  }, [brandsFiltered])

  const params: SwiperParams = {
    rebuildOnUpdate: true,
    slidesPerGroup: useResolution().isDesktop ? 5 : 1,
    slidesPerView: 'auto',
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    freeMode: !useResolution().isDesktop,
    freeModeMomentumBounce: false,
    grabCursor: true,
    simulateTouch: true,
    speed: 1300,
    centeredSlides: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  }

  const isGray = (isRefined: boolean) => {
    return !isRefined && currentRefinement.length > 0
  }

  return (
    <Wrapper
      IconRight="https://images.ctfassets.net/16npdkkoi5mj/31JdIGZYomjqVIatKBfQjb/2d46786c7dc837816bf76ec1a642eb39/chevron_right.png"
      IconLeft="https://images.ctfassets.net/16npdkkoi5mj/4PRKLyEQRdHKKUQmAqZQWZ/56eda383827ab0d6f4b656ae3a7e109a/chevron_left.png"
    >
      <Swiper {...params}>
        {brandsFiltered.map((brand) => (
          <div className={`item ${brand.isRefined ? 'is-refined' : ''}`} key={brand.label}>
            <Anchor
              onClick={(event) => {
                event.preventDefault()
                refine(brand.value)
                setColor(false)
              }}
              className="CategoryBrands__button"
              style={{
                filter: (brand?.isRefined || color) && !isGray(brand.isRefined) ? 'grayscale(0)' : 'grayscale(1)',
              }}
            >
              <Image
                src={brand.src}
                alt={brand.label}
                preload={false}
                className="CategoryBrands__image"
                params={{ w: 80 }}
              />
            </Anchor>
          </div>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default connectRefinementList<Props>(RefinementList)
