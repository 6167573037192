import React, { useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { breakpoints, City, sendDataToGTM } from '@ecommerce/shared'
import Layout from '../components/Layout'
import CategoryContainer from '../components/CategoryContainer'
import withPageTransition from '../components/withPageTransition'
import { useCatalogHitClick } from '../hooks/useCatalogHitClick'
import { PgPageProps, FlatLocationProductCategory } from '../types/PgPages'
import { CategoryResponse, BrandResponse } from '../graphql/categoryQuery'
import { Filter } from '../graphql/contentfulTypes'
import useContentfulGlobal from '../hooks/useContentfulGlobal'
import useTheme from '../hooks/useTheme'

type CategoryNode = {
  label: string
  urlImage: string
  brands: BrandResponse[]
  categoryLabels: CategoryResponse[]
  currentCity: City
  productCategories: FlatLocationProductCategory[]
  filters?: Filter[]
}

const WrapperContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 231px);
  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 194px);
  }
  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
    padding: 40px 20px 0 20px;
  }

  @media (${breakpoints.desktop.medium}) and (${breakpoints.desktop.max}) {
    padding: 40px;
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    padding: 40px 120px 0 140px;
  }
`

const CategoryView = (props: PgPageProps<{ category: CategoryNode }>) => {
  const { category, productCategories } = props.pageContext
  const { location } = props
  const { currentCity, filters } = category
  const refinementList = location?.state?.refinementList
  const refinementListSelected = refinementList && Object.keys(refinementList).filter((item) => refinementList[item])

  const { defaultCategoryFilters } = useContentfulGlobal()
  const themeId = location?.state?.themeId
  const { mergeThemes } = useTheme(themeId)

  const onCatalogHitClick = useCatalogHitClick({ listName: `Categorías - ${category.label}`, currentCity, themeId })

  useEffect(() => {
    sendDataToGTM({ event: 'categoryView', pageTitle: category.label, categoryName: category.label })
  }, [])

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout
        id="layout"
        title={category.label}
        pathName={`/${currentCity.slug}/category`}
        currentCity={currentCity}
        categories={productCategories}
      >
        <WrapperContainer>
          <CategoryContainer
            categoryFilters={filters || defaultCategoryFilters}
            category={category.label}
            brands={category.brands}
            currentCity={currentCity}
            refinementListSelected={refinementListSelected}
            onHitClick={onCatalogHitClick}
          />
        </WrapperContainer>
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(CategoryView)
