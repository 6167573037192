import React, { useState } from 'react'
import styled from 'styled-components'
import { breakpoints, ZIndex } from '@ecommerce/shared'
import RefinementList, { BrandProps } from './RefinementList'
import { Anchor } from '../common'
import IsFilter from '../Algolia/FilterSelected'

const WrapperBrands = styled.div<{ hasBrands: boolean }>`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  z-index: ${ZIndex.default};
  display: ${(props) => (props.hasBrands ? 'static' : 'none')};

  @media (${breakpoints.desktop.min}) {
    margin-left: auto;
    border-top: solid 1px ${(props) => props.theme.colors.medium};
  }
`

const Title = styled.div`
  width: 100%;
  height: 30px;
  padding: 15px 20px 0 20px;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .CategoryBrands__ {
    &title {
      font-size: 15px;
      color: ${(props) => props.theme.colors.black};
      align-items: center;
    }

    &filter {
      width: 65px;
      height: 18px;
      display: flex;
      justify-content: space-between;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media (${breakpoints.desktop.min}) {
    display: none;
  }
`

interface Data {
  label: string
  src: string
}
interface Props {
  brands: BrandProps[]
  onOpenFilter: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export default (props: Props) => {
  const { brands, onOpenFilter } = props
  const [hasBrands, setHasBrands] = useState(false)
  function cleanBrands(value: boolean) {
    setHasBrands(value)
  }

  return (
    <WrapperBrands hasBrands={hasBrands}>
      <Title>
        <span className="CategoryBrands__title">Marcas</span>
        <Anchor className="CategoryBrands__filter" onClick={onOpenFilter}>
          <IsFilter />
          <span>Filtros</span>
        </Anchor>
      </Title>
      <RefinementList attribute="brandName" brands={brands} cleanBrands={cleanBrands} limit={1000} />
    </WrapperBrands>
  )
}
